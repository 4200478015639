exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innovation-tsx": () => import("./../../../src/pages/innovation.tsx" /* webpackChunkName: "component---src-pages-innovation-tsx" */),
  "component---src-pages-operational-consulting-tsx": () => import("./../../../src/pages/operational-consulting.tsx" /* webpackChunkName: "component---src-pages-operational-consulting-tsx" */),
  "component---src-pages-operational-strategy-tsx": () => import("./../../../src/pages/operational-strategy.tsx" /* webpackChunkName: "component---src-pages-operational-strategy-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-product-management-tsx": () => import("./../../../src/pages/product-management.tsx" /* webpackChunkName: "component---src-pages-product-management-tsx" */),
  "component---src-pages-web-application-development-tsx": () => import("./../../../src/pages/web-application-development.tsx" /* webpackChunkName: "component---src-pages-web-application-development-tsx" */)
}

